import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import LayoutTmpl from "../components/layout";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@mui/material/Avatar";
import {blueGrey} from "@mui/material/colors";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import Link from "@material-ui/core/Link";

const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
    marginTop: "20px",
    margin: 'auto',
}));

const StyledAvatar = styled(Avatar)(({theme}) => ({
    backgroundColor: blueGrey[500]
}));
const Feedback = () => {

    const [elementData, setElementData] = useState({
        categoryId: null,
        comment: false
    });
    const [captcha, setCaptcha] = useState(false);
    const [sid, setSid] = useState(false);
    const [code, setCode] = useState(false);

    function handleFeedbackChange(event) {

        const target = event !== undefined ? event.target : undefined;

        if (target !== undefined) {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            if (name === "code") {
                setCode(value);
            } else {
                setElementData({
                    ...elementData, [name]: value
                });
            }
        }
    }

    function getCaptcha() {
        fetch("https://api.ocenivay.com/api/captcha-sid.php", {
            method: "POST",
            // body: JSON.stringify({values})
        })
            .then(response => response.json())
            .then(data => {
                setCaptcha('<img src="https://api.ocenivay.com/bitrix/tools/captcha.php?captcha_sid=' + data + '" width="180" height="40" alt="CAPTCHA">')
                setSid(data);
                setCode("");
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            })
    }

    useEffect(() => {
        getCaptcha()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function sendMessage() {
        let formData = new FormData();
        let jwt = "";
        let email = "";
        if (localStorage.getItem("gatsbyUser") !== null) {
            if (JSON.parse(localStorage.getItem("gatsbyUser")).jwt !== null) {
                jwt = JSON.parse(localStorage.getItem("gatsbyUser")).jwt;
            }
            if (JSON.parse(localStorage.getItem("gatsbyUser")).email !== null) {
                email = JSON.parse(localStorage.getItem("gatsbyUser")).email;
            }
        }
        formData.append(
            "jwt", jwt,
        );
        formData.append(
            "email", email,
        );
        formData.append(
            "sid", sid,
        );
        formData.append(
            "code", code,
        );

        for (const [key, value] of Object.entries(elementData)) {
            if (key !== "code") formData.append(key, value)
        }

        console.log(Object.assign(formData));

        axios({
            method: "POST",
            data: Object.assign(formData),
            url: "https://api.ocenivay.com/api/message-send.php",
            responseType: 'json',
        })
            .then(res => {
                if (res.data.type === undefined) {
                    setElementData({
                        categoryId: false,
                        comment: false
                    });
                }
                getCaptcha()
                setSnackbarMessage(res.data.severity, res.data.message)
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function setSnackbarMessage(severity, message) {
        severity = severity ? severity : "warning";
        message = message ? message : "Что-то пошло не так... Попробуйте позже...";
        setSeverity(severity)
        setMessage(message)
        handleClick();
    }

    return (
        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>Обратная связь</title>
                <meta name="description" content="Обратная связь"/>
                <meta name="robots" content="noindex"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open} autoHideDuration={4000} onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <LayoutTmpl>

                <main>

                    <StyledContainer maxWidth="md">

                        <StyledContainer component="main" maxWidth="xs">

                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid>
                                    <StyledAvatar>
                                        <ContactMailIcon/>
                                    </StyledAvatar>
                                </Grid>
                                <Grid style={{
                                    marginBottom: "15px",
                                }}>
                                    <Typography component="h1" variant="h5"
                                                style={{
                                                    textAlign: "center"
                                                }}
                                    >
                                        Обратная связь
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>


                                    <FormControl fullWidth>
                                        <InputLabel
                                            htmlFor="categoryId"
                                            style={{
                                                marginLeft: "15px",
                                                marginTop: "-7px",
                                            }}>Тема</InputLabel>
                                        <Select
                                            variant="outlined"
                                            id="categoryId"
                                            name="categoryId"
                                            displayEmpty
                                            label="Статус"
                                            value={elementData.categoryId ? elementData.categoryId : null}
                                            onChange={handleFeedbackChange}
                                            inputProps={{
                                                name: "categoryId",
                                                id: "categoryId"
                                            }}
                                        >
                                            <MenuItem value={68}>Ошибка на сайте</MenuItem>
                                            <MenuItem value={69}>Сотрудничество</MenuItem>
                                            <MenuItem value={70}>Другое</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        id="comment"
                                        name="comment"
                                        label="Сообщение"
                                        type="text"
                                        value={elementData.comment ? elementData.comment : ""}
                                        fullWidth
                                        multiline
                                        onChange={handleFeedbackChange}
                                        minRows={8}
                                        maxRows={12}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{
                                    textAlign: "center"
                                }}>
                                    <div dangerouslySetInnerHTML={{__html: captcha}}></div>
                                </Grid>
                                <Grid item xs={6} style={{
                                    paddingLeft: "25px",
                                    paddingTop: "19px",
                                    textAlign: "center"
                                }}>
                                    <Link href="#" onClick={getCaptcha}>
                                        Обновить
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={handleFeedbackChange}
                                        onBlur={handleFeedbackChange}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        value={code ? code : ""}
                                        fullWidth
                                        id="code"
                                        label="Латинские символы"
                                        name="code"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{
                                    textAlign: "right"
                                }}>
                                    <Button
                                        onClick={sendMessage}
                                        style={{
                                            margin: '25px 0 0 0'
                                        }}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                    >
                                        Отправить
                                    </Button></Grid>
                            </Grid>


                        </StyledContainer>

                    </StyledContainer>

                </main>

            </LayoutTmpl>
        </>
    );

}

Feedback.propTypes = {
    window: PropTypes.func,
};

export default Feedback